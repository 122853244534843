/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import { useSnackbar } from "notistack";
import { format } from "date-fns";

import clsx from "clsx";

import { Autocomplete } from "@material-ui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";

import { AccountBalanceWallet, Payment } from "@material-ui/icons";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import EmailIcon from "@material-ui/icons/Email";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import HistoryIcon from "@material-ui/icons/History";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PageviewIcon from "@material-ui/icons/Pageview";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import SearchIcon from "@material-ui/icons/Search";

import { TypesRolesPermissions } from "@context/UserContext/RolesPermissions";
import { useUserDispatch, useUserState } from "@context/UserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Roles } from "@context/AuthContext/AuthContext";

import {
  ICallJobsService,
  JobService,
} from "@modules/jobs/models/ICallJobsService";

import { ReactComponent as BranchIcon } from "@assets/source-branch.svg";

import { generatePermissionMatcher, getRedirectUrl } from "@utils/index";
import { useFlags } from "flagsmith/react";
import { Types } from "@ioc/types";

import appConfig from "@config/appConfig";
import ROUTES from "@config/routes";

import { DialogUploadCosts } from "@components/Header/DialogUploadCosts";
import { DialogSuccessUploadCosts } from "@components/Header/DialogSuccessUploadCosts";

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      height: "7.2rem",
      width: "7.2rem",

      marginTop: "3.2rem",
      marginBottom: "3.2rem",
    },
    userName: {
      fontSize: "2.4rem",
      fontWeight: "bold",
      color: theme.palette.getContrastText(theme.palette.background.paper),
      marginBottom: "0.8rem",
    },
    containerDrawer: {
      margin: "0 1.6rem",
      width: "80vw",
      [theme.breakpoints.up("md")]: { width: "20vw" },
    },
    divider: { margin: "2rem 0" },
    listItem: {
      fontSize: "1.6rem",
      color: theme.palette.getContrastText(theme.palette.background.paper),
      marginBottom: "0.8rem",
      borderRadius: "0.5rem",
      "& img": {
        filter:
          "invert(45%) sepia(0%) saturate(1244%) hue-rotate(218deg) brightness(85%) contrast(92%)",
      },
    },
    listItemSelected: {
      backgroundColor: `${theme.palette.primaryLight.main} !important`,
      color: theme.palette.primary.main,
      "& svg": {
        color: theme.palette.primary.main,
      },
      "& img": {
        filter:
          "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
      },
    },
    listItemHover: {
      "&:hover": {
        backgroundColor: theme.palette.primaryLight.main,
        color: theme.palette.primary.main,
        "& img": {
          filter:
            "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
        },
        "& svg": {
          color: theme.palette.primary.main,
        },
      },
    },
    autocompleteRoot: { width: "100%", marginTop: "1rem" },
    matchText: { color: theme.palette.primary.main },
    accordionRoot: { "&:before": { display: "none" } },
    accordionSumaryRoot: {
      padding: 0,
      "&.Mui-expanded": { minHeight: "auto" },
    },
    accordionSumaryContent: {
      margin: 0,
      "&.Mui-expanded": { margin: 0 },
    },
    accordionDetailsRoot: {
      paddingTop: 0,
      paddingBottom: 0,
      width: "100%",
      "& > .MuiList-root": { width: "100%" },
    },
  })
);

interface Props {
  open: boolean;
  setOpen: Function;
}

interface MenuListUser {
  icon: JSX.Element;
  text: string;
  link: string;
  accordion: MenuListUserAccordion[];
  hasModulePermission?: (permissionRef: string) => boolean;
  onClick?: () => void;
}

interface MenuListUserAccordion {
  icon: JSX.Element;
  text: string;
  link: string;
  hasPermission: (permissionRef: string) => boolean;
  hasComponent?: string;
  onClick?: () => void;
}

const ListDrawerUser: React.FC = () => {
  const iocContext = useIoCContext();
  const classes = useStyles();

  const user = useUserState();
  const { SYSTEM_MODULES } = user.state;

  const [openDialogSend, setOpenDialogSend] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [isLoadingCallJobService, setIsLoadingCallJobService] = useState(false);

  const snackbar = useSnackbar();
  const callJobsService = iocContext.serviceContainer.get<ICallJobsService>(
    Types.Jobs.ICallJobsService
  );

  const handleJobService = async () => {
    try {
      setIsLoadingCallJobService(true);
      await callJobsService.execute(JobService.ANP_CUSTOMER_JOB);
      await new Promise((res) => setTimeout(res, 1000));
      snackbar.enqueueSnackbar(
        "A requisição foi enviada, logo os emails serão disparados.",
        { variant: "success" }
      );
    } catch (err) {
      snackbar.enqueueSnackbar(
        "Ocorreu um erro no envio da requisição, contate o suporte.",
        { variant: "error" }
      );
    } finally {
      setIsLoadingCallJobService(false);
    }
  };

  const menuListUser: MenuListUser[] = [
    {
      icon: <AccountBalanceIcon />,
      text: "Simular preços",
      link: ROUTES.USER_ROUTES.SIMULATION_COSTS,
      hasModulePermission: generatePermissionMatcher(
        TypesRolesPermissions.ROLE_SIMULATION_COSTS
      ),
      accordion: [],
    },

    {
      icon: <HistoryIcon />,
      text: "Programação rodoviária",
      link: "#",
      // hasModulePermission: generatePermissionMatcher(
      //   TypesRolesPermissions.ROLE_SCHEDULE
      // ),
      onClick: () => {
        window.location.href = getRedirectUrl();
      },
      accordion: [],
    },
    {
      icon: <SearchIcon />,
      text: "Consultas",
      link: "#",
      accordion: [
        {
          icon: <AttachMoneyIcon />,
          text: "Consultar preços",
          link: ROUTES.USER_ROUTES.APPOINTMENT_PRICE,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_APPOINTMENT_PRICE
          ),
        },
        {
          icon: <EqualizerIcon />,
          text: "Consultar vendas",
          link: ROUTES.USER_ROUTES.APPOINTMENT_SALES,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_APPOINTMENT_SALES
          ),
        },
        {
          icon: <EqualizerIcon />,
          text: "Consultar vendas/marketing",
          link: ROUTES.USER_ROUTES.APPOINTMENT_MARKETING_PLAN,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_APPOINTMENT_MARKETING_PLAN
          ),
        },
        {
          icon: <PersonIcon />,
          text: "Consultar preços de cliente",
          link: ROUTES.USER_ROUTES.APPOINTMENT_PRICE_CUSTOMER,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_APPOINTMENT_PRICE_CUSTOMER
          ),
        },
        {
          icon: <AccountBalanceWalletIcon />,
          text: "Consultar carteira",
          link: ROUTES.USER_ROUTES.APPOINTMENT_WALLET,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_APPOINTMENT_WALLET
          ),
        },
        {
          icon: <AssignmentIndIcon />,
          text: "Partidas individuais por cliente",
          link: ROUTES.USER_ROUTES.APPOINTMENT_TITLE,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_APPOINTMENT_TITLE
          ),
        },
        {
          icon: <FindInPageIcon />,
          text: "Consultar NFs",
          link: ROUTES.USER_ROUTES.APPOINTMENT_INVOICES,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_APPOINTMENT_INVOICES
          ),
        },
        {
          icon: <FindInPageIcon />,
          text: "Consultar venda margem",
          link: ROUTES.USER_ROUTES.SALES_MARGIN,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_SALES_MARGIN
          ),
        },
        {
          icon: <FindInPageIcon />,
          text: "Consultar cota",
          link: ROUTES.USER_ROUTES.QUOTE_SALE,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_QUOTE_SALE
          ),
        },
      ],
    },
    {
      icon: <AttachMoneyIcon />,
      text: "Custos",
      link: "#",
      accordion: [
        {
          icon: <AddCircleIcon />,
          text: "Cadastrar custos",
          link: "#",
          onClick: () => setOpenDialogSend(true),
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_ADD_COAST
          ),
        },
        {
          icon: <PageviewIcon />,
          text: "Consultar custos ativos",
          link: ROUTES.USER_ROUTES.ATIVE_COSTS,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_ATIVE_COSTS
          ),
        },
        {
          icon: <HistoryIcon />,
          text: "Consultar historico / agendado",
          link: ROUTES.USER_ROUTES.HISTORY_COSTS,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_HISTORY_COSTS
          ),
        },
        {
          icon: <HistoryIcon />,
          text: "Consultar configuração de margem ativas",
          link: ROUTES.USER_ROUTES.SETTINGS_COSTS,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_SETTINGS_COSTS
          ),
        },
        {
          icon: <HistoryIcon />,
          text: "Consultar configuração de margem historico / agendado",
          link: ROUTES.USER_ROUTES.HISTORY_SETTINGS_COSTS,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_HISTORY_SETTINGS_COSTS
          ),
        },
        {
          icon: <AddCircleIcon />,
          text: "Cadastrar configuração de margem",
          link: "#",
          onClick: () => setOpenDialogSend(true),
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_ADD_CONFIG_MARGIN
          ),
        },
      ],
    },
    {
      icon: <LockOpenIcon />,
      text: "Gerenciar Acessos dos Assessores",
      link: ROUTES.ADMIN_ROUTES.VIEW_ACCESSES,
      hasModulePermission: generatePermissionMatcher(
        TypesRolesPermissions.ROLE_VIEW_ACCESSES
      ),
      accordion: [],
    },
    {
      icon: isLoadingCallJobService ? (
        <CircularProgress color="primary" size={20} />
      ) : (
        <EmailIcon />
      ),
      text: "relatório SAP x ANP",
      link: "#",
      accordion: [
        {
          icon: <AddCircleIcon />,
          text: "Enviar relatório SAP x ANP",
          link: "#",
          onClick: handleJobService,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_TRIGGER_JOBS
          ),
        },
      ],
    },
    {
      icon: <Payment />,
      text: "Financeiro",
      link: "#",
      accordion: [
        {
          icon: <EqualizerIcon />,
          text: "Dashboard",
          link: ROUTES.USER_ROUTES.FINANCIAL_DASHBOARD,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_FINANCIAL_DASHBOARD
          ),
        },
        {
          icon: <AccountBalanceWallet />,
          text: "Consultar Partidas",
          link: ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS,
          hasPermission: generatePermissionMatcher(
            TypesRolesPermissions.ROLE_FINANCIAL_INSTALLMENTS
          ),
        },
      ],
    },
  ];

  const [menuList, setListMenu] = useState<MenuListUser[]>(menuListUser);

  {
    /* ATUALIZA O MENU COM BASE NAS PERMISSOES DO USUARIO e FEATURE FLAGS */
  }
  const { user_access_management } = useFlags(["user_access_management"]);
  useEffect(() => {
    if (!user_access_management.enabled) {
      const exceptionListByText = ["Dashboard", "Consultar vendas/marketing"]; // Lista exclusiva que guarda excessoes de regra de permissao - [legado]
      const removeAcessControl: MenuListUser[] = menuListUser.map((item) => ({
        ...item,
        hasModulePermission: item.hasModulePermission,
        accordion: item.accordion.map(
          (acc) =>
            exceptionListByText.some((exception) => exception === acc.text)
              ? { ...acc } // Retorna o item sem alteração pois nao pode ser alterado por logica escrita antes da atualizacao
              : { ...acc, hasPermission: () => true } // Retorna o item com total liberacao da permissao
        ),
      }));
      setListMenu(removeAcessControl);
      return;
    }

    const idAdmin = user.state.roles.includes(Roles.ADMIN);
    if (idAdmin) {
      setListMenu(menuListUser);
      return;
    }

    const filteredMenus = menuListUser.filter((item) => {
      // Verificar se possui accordions && verificar se possui permissao em todos os modulos do accordion, caso nao tenha deve esconder todo o modulo
      const conditionHasSubmodulesPermission =
        item.accordion.length > 0
          ? item.accordion.map((itemAccordion) =>
              SYSTEM_MODULES.some(itemAccordion.hasPermission)
            )
          : [false];
      item.accordion = item.accordion.filter(
        (_, index) => conditionHasSubmodulesPermission[index]
      );

      // Verificar se nao possui accordions e se possui permissao no modulo
      const conditionHasModulePermission =
        item.accordion.length === 0 && item.hasModulePermission
          ? SYSTEM_MODULES.some(item.hasModulePermission)
          : false;

      return (
        conditionHasSubmodulesPermission.some((item) => item) ||
        conditionHasModulePermission
      );
    });

    setListMenu(filteredMenus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.state.SYSTEM_MODULES, user.state.roles]);

  const location = useLocation();

  return (
    <List>
      {menuList.map((item, index) => {
        return item.accordion.length === 0 ? (
          <ListItem
            key={index}
            button
            className={classes.listItem}
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemHover,
            }}
            selected={location.pathname === item.link}
            component={Link}
            to={item.link}
            onClick={item.onClick}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.text}
          </ListItem>
        ) : (
          <Accordion
            square
            elevation={0}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              classes={{
                content: classes.accordionSumaryContent,
                root: classes.accordionSumaryRoot,
              }}
            >
              <ListItem
                button
                className={classes.listItem}
                style={{ marginBottom: 0 }}
                classes={{
                  selected: classes.listItemSelected,
                  button: classes.listItemHover,
                }}
                onClick={item.onClick}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.text}
              </ListItem>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
              <List>
                {item.accordion
                  .filter((itemAccordion) => itemAccordion.hasPermission)
                  .map((itemAccordion, indexAccordion) => {
                    return itemAccordion.onClick ? (
                      <ListItem
                        key={indexAccordion}
                        button
                        className={classes.listItem}
                        classes={{
                          selected: classes.listItemSelected,
                          button: classes.listItemHover,
                        }}
                        onClick={itemAccordion.onClick}
                        disabled={!itemAccordion.hasPermission}
                      >
                        <ListItemIcon>{itemAccordion.icon}</ListItemIcon>
                        {itemAccordion.text}
                      </ListItem>
                    ) : (
                      <ListItem
                        disabled={!itemAccordion.hasPermission}
                        key={indexAccordion}
                        button
                        className={classes.listItem}
                        classes={{
                          selected: classes.listItemSelected,
                          button: classes.listItemHover,
                        }}
                        selected={location.pathname === itemAccordion.link}
                        component={Link}
                        to={itemAccordion.link}
                      >
                        <ListItemIcon>{itemAccordion.icon}</ListItemIcon>
                        {itemAccordion.text}
                      </ListItem>
                    );
                  })}
                ;
              </List>
              <DialogUploadCosts
                open={openDialogSend}
                onClose={() => setOpenDialogSend(false)}
                onSuccess={() => setOpenDialogSuccess(true)}
              />
              <DialogSuccessUploadCosts
                open={openDialogSuccess}
                onClose={() => setOpenDialogSuccess(false)}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </List>
  );
};

const DrawerMenu: React.FC<Props> = ({ open, setOpen }) => {
  const classes = useStyles();
  const location = useLocation();
  const userState = useUserState();
  const dispatchUser = useUserDispatch();

  const getInitialLettersName = (name: string) => {
    const splitedName = name.split(" ");
    let letters = "";
    for (let splited of splitedName) {
      letters += splited[0];
    }
    return letters;
  };

  return (
    <Drawer
      open={open}
      onClose={() => setOpen((oldValue: boolean) => !oldValue)}
    >
      <div className={classes.containerDrawer}>
        <Avatar className={classes.avatar}>
          {getInitialLettersName(userState.state.username)}
        </Avatar>
        <Typography className={classes.userName}>
          {userState.state.username}
        </Typography>
        <Typography>Manaus/Amazonas</Typography>

        <Autocomplete
          options={userState.state.assessorList}
          className={classes.autocompleteRoot}
          getOptionLabel={(option) => `${option.bpID} - ${option.bpName}`}
          loading={userState.state.loadingAssessorList}
          value={userState.state.bpSelected}
          onChange={(e, value) =>
            dispatchUser({
              type: "APP_SET_ASSESSOR",
              payload: {
                bpID: value.bpID,
                type: value.type,
                bpName: value.bpName,
                userID: userState.state.userID,
              },
            })
          }
          renderOption={(option, { inputValue }) => {
            const matches = match(
              `${option.bpID} - ${option.bpName}`,
              inputValue
            );
            const parts = parse(`${option.bpID} - ${option.bpName}`, matches);

            return (
              <React.Fragment>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                      className={clsx(part.highlight && classes.matchText)}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </React.Fragment>
            );
          }}
          disableClearable
          renderInput={(params) => (
            <TextField {...params} label="Carteira" color="primary" />
          )}
        />

        <Divider className={classes.divider} />

        <ListDrawerUser />

        <Divider className={classes.divider} />

        <Grid
          container
          justify="space-around"
          alignItems="center"
          direction="column"
        >
          <Grid item container justify="center">
            <Typography>Versão:</Typography>

            <Icon style={{ marginLeft: "1rem" }} component={BranchIcon} />
            <Typography>{appConfig.version.number}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              Atualizado em: {format(appConfig.version.date, "dd/MM/yyyy")}
            </Typography>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <List>
          <ListItem
            button
            className={clsx(classes.listItem)}
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemHover,
            }}
            selected={location.pathname === ROUTES.USER_ROUTES.CALL_CENTERS}
            component={Link}
            to={ROUTES.USER_ROUTES.CALL_CENTERS}
          >
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            Central de Atendimento
          </ListItem>
          <ListItem
            button
            className={clsx(classes.listItem)}
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemHover,
            }}
            selected={location.pathname === ROUTES.USER_ROUTES.CHANGE_PASSWORD}
            component={Link}
            to={ROUTES.USER_ROUTES.CHANGE_PASSWORD}
          >
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            Trocar Senha
          </ListItem>
          <ListItem
            button
            className={clsx(classes.listItem)}
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemHover,
            }}
            component="a"
            href={
              process.env.REACT_APP_ENVIRONMENT === "homologation"
                ? appConfig.cognito.urlLogout.homologation
                : process.env.NODE_ENV === "production"
                ? appConfig.cognito.urlLogout.production
                : appConfig.cognito.urlLogout.development
            }
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            Sair
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default DrawerMenu;
