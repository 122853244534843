import { TableQuotes } from "@components/ChartsDashboard/TableQuoteSale";
import Layout from "@components/Layout";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  AggregateQuoteSaleResponse,
  IGetChartDataService,
} from "@modules/dashboard/models/IGetChartDataService";
import { SimulationCostsProvider } from "@pages/Costs/SimulationCosts/SimulationCostsContext";
import React, { useCallback, useEffect, useState } from "react";
import {
  useHomeDashboard,
  HomeDashboardProvider,
} from "./HomeDashboardContext";
import {
  IGetCentersResponse,
  IGetCentersService,
} from "@modules/user/models/IGetCentersService";
import { blackListCenters } from "@utils/blackListCenters";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      itemSelect: {
        display: "flex",
        alignItems: "flex-start",
      },
      iconNotDisabled: {
        opacity: "0.6",
      },
      iconsDisabled: {
        opacity: "0.12",
      },
      titleDisabled: { opacity: "0.6" },
    })
);

export const Container = () => {
  const iocContext = useIoCContext();
  const { filters, dateCharts } = useHomeDashboard();
  const [listItem, setListItem] = useState("C001");
  const [loading, setLoading] = useState(false);
  const [aggregateQuoteSale, setAggregateQuoteSale] = useState<
    AggregateQuoteSaleResponse[]
  >([]);
  const classes = useStyles();
  const [centers, setCenters] = useState<IGetCentersResponse[]>();

  const changePasswordService = iocContext.serviceContainer.get<
    IGetCentersService
  >(Types.Users.IGetCentersService);

  const getDataChartService = iocContext.serviceContainer.get<
    IGetChartDataService
  >(Types.Dashboard.IGetChartDataService);

  const fetchCenters = useCallback(async () => {
    try {
      const response = await changePasswordService.execute();
      const filteredCenters = response.filter(
        (center) => !blackListCenters.some(({ id }) => center.id === id)
      );
      setCenters(filteredCenters);
      console.log(filteredCenters);
    } catch {
      console.log("Error!");
    }
  }, [changePasswordService]);

  useEffect(() => {
    fetchCenters();
  }, [fetchCenters]);

  const fetchQuoteSale = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getDataChartService.getAggregateQuoteSale(
        listItem
      );
      setAggregateQuoteSale(response);
    } catch {
      setLoading(false);
      console.log("Error!");
    } finally {
      setLoading(false);
    }
  }, [getDataChartService, listItem]);

  useEffect(() => {
    fetchQuoteSale();
  }, [fetchQuoteSale]);

  const renderWithdrawBasis = () => {
    if (!centers) return null;

    return centers.map((ele) => {
      return (
        <MenuItem key={ele.id} value={ele.id} className={classes.itemSelect}>
          <strong>{ele.id}</strong>-{ele.desc}
        </MenuItem>
      );
    });
  };

  return (
    <Layout disableFooter enableMargins fullHeight={false}>
      <h1> Tabelas de cotas </h1>

      <FormControl fullWidth>
        <InputLabel id="teste-label"> Centro/Polo</InputLabel>
        <Select
          labelId="teste-label"
          id="teste"
          value={listItem}
          onChange={({ target }) => {
            setListItem(target.value as string);
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {renderWithdrawBasis()}
        </Select>
      </FormControl>

      <TableQuotes
        center={listItem}
        filters={filters}
        from={dateCharts[dateCharts.selected].from as string}
        to={dateCharts[dateCharts.selected].end as string}
        aggregate={aggregateQuoteSale ? aggregateQuoteSale : []}
        loading={loading}
      />
    </Layout>
  );
};

export const QuoteSalePage: React.FC = () => {
  return (
    <HomeDashboardProvider>
      <SimulationCostsProvider>
        <Container />
      </SimulationCostsProvider>
    </HomeDashboardProvider>
  );
};
